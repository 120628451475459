.text-underline-hover {
  text-decoration: none;
}

.text-underline-hover:hover {
  text-decoration: underline;
}

/*Another way to do animation with underline*/
/* https://codepen.io/sreisner/pen/yOOOjx */
