.card .card-img-top {
  cursor: pointer;
}

.card .card-img-top:hover {
  object-fit: cover;
  border-style: solid;
  border-color: #2081e2;
  border-width: 6px;
}

.card .card-img-top.selected {
  object-fit: cover;
  border-style: solid;
  border-color: #2081e2;
  border-width: 6px;
}
