// $primary: #2081e2;
$theme-colors: (
  // "primary": #2081e2,
  "primary": #2081e2
);
$min-contrast-ratio: 3.9;

//bold, semi-bold, regular, medium
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
// $btn-font-family: "poppins";

@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";

// $my-color: (
//   "lightblue": #2081e2,
// );
// $theme-colors: map-merge($my-color, $theme-colors);
@import "../node_modules/bootstrap/scss/bootstrap.scss";

//////////////////
:root {
  --bs-body-font-family: "Poppins", sans-serif;
}
