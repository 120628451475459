/* .footer {
  margin-top: 1rem;
  padding: 1rem;
  background-color: rgb(235, 195, 64);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
} */

.footer {
  background-color: gray;
  text-align: center;
  margin-top: auto;
  padding: 8px;
}
